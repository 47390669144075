<template>
  <div>
    <b-card title="Masterumah Admin 🚀">
      <b-card-text v-if="userData.role_name == 'Admin Tukang Pro'">Welcome to {{ userData.vendor_name }}.</b-card-text>
      <b-card-text v-else>Welcome to {{ userData.user_name }}.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      userData: "",
      firstAkses: true,
    }
  },
  mounted() {
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.firstAkses = JSON.parse(localStorage.getItem('isFirstAkses'));
    if(this.firstAkses == true) {
      localStorage.setItem('isFirstAkses', false)
      window.location.reload()
    }
  }
}
</script>

<style>

</style>
